<template>
  <div class="container-fluid pt-3">
    <AddressesContactSearch @close="toggleSearch" :searchActive="searchActive">
      <template #searchaction="searchaction">
        <button
          v-for="category in address.categories"
          :key="category.id"
          @click="addContact(searchaction.address, category.pivot.id, address.id)"
          class="btn btn-success btn-icon mb-1 w-100 is-rows-el edit-btn text-start"
          :title="t('general.link')"
        >
          <svg width="20" height="20" class="bi" fill="currentColor">
            <use xlink:href="/assets/img/bootstrap-icons.svg#person-plus-fill"></use>
          </svg>
          {{ displayName }} {{ t("general.linkas") }}: {{ category.category }}
        </button>
      </template>
    </AddressesContactSearch>
    <div class="gws_content_container p-3">
      <!-- title -->
      <div class="row g-3">
        <div class="col-md-12">
          <h1 class="text-center mb-0">{{ t("nav.contactof") }} {{ t("general.edit") }}</h1>
          <div class="subtitle-name mb-3 text-center">{{ displayName }}</div>
        </div>
      </div>
      <!-- nav -->
      <div class="row g-3">
        <div class="col-md-12">
          <AddressNavigation :categories="address.categories" :id="$route.params.id"></AddressNavigation>
        </div>
      </div>
      <!-- controlbar -->
      <div class="gws_control_bar d-flex justify-content-center align-items-center gap-2 p-2 my-2">
        <div class="gws_control_bar_item">
          <!-- new -->
          <button @click="toggleSearch" class="btn btn-success btn-icon mt-2 mt-lg-0">
            <svg class="bi" width="20" height="20" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#search"></use>
            </svg>
            {{ t("general.link_contact") }}
          </button>
        </div>
      </div>
      <!-- resluts -->
      <!-- <pre>{{ address }}</pre> -->
      <!-- <pre>{{ addresscontacts }}</pre> -->
      <div class="gws_address_search">
        <div class="card-group gws_card-group mt-3" v-if="addresscontacts.length > 0">
          <div
            class="card gws_search_results mb-sm-2 text-dark me-2"
            v-for="(contact, index) in addresscontacts"
            :key="index"
          >
            <div class="card-header d-flex justify-content-end">
              <button
                @click="removeContactOf(contact.id)"
                class="btn btn-warning btn-icon me-2 is-rows-el edit-btn"
                :title="t('general.remove')"
              >
                <svg width="20" height="20" class="bi" fill="currentColor">
                  <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
                </svg>
                {{ t("general.remove") }}
              </button>
              <router-link
                :to="'/addresses/edit/' + contact.address.id"
                class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
                :title="t('general.edit')"
              >
                <svg width="20" height="20" class="bi" fill="currentColor">
                  <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                </svg>
                {{ t("general.edit") }}
              </router-link>
            </div>
            <div class="card-body">
              <div class="card-text">
                <dl>
                  <template v-if="contact.address.name !== null">
                    <dt>{{ t("address.name") }}</dt>
                    <dd>{{ contact.address.name }}</dd>
                  </template>
                  <template v-if="contact.address.surname !== null">
                    <dt>{{ t("address.surname") }}</dt>
                    <dd>
                      {{ contact.address.salutation }} {{ contact.address.forename }} {{ contact.address.surname }}
                    </dd>
                  </template>
                  <template v-if="contact.address.email !== null">
                    <dt>{{ t("address.email") }}</dt>
                    <dd>
                      <a :href="'mailto:' + contact.address.email">{{ contact.address.email }}</a>
                    </dd>
                  </template>
                  <template v-if="contact.address.tel !== null">
                    <dt>{{ t("address.tel") }}</dt>
                    <dd>
                      <a :href="'tel:' + phone(contact.address)">{{ phone(contact.address) }}</a>
                    </dd>
                  </template>
                  <template v-if="contact.address.mobile !== null">
                    <dt>{{ t("address.mobile") }}</dt>
                    <dd>
                      <a :href="'tel:' + mobile(contact.address)">{{ mobile(contact.address) }}</a>
                    </dd>
                  </template>
                  <template v-if="contact.address.fax !== null">
                    <dt>{{ t("address.fax") }}</dt>
                    <dd>
                      {{ fax(contact.address) }}
                    </dd>
                  </template>
                  <template v-if="contact.address.website !== null">
                    <dt>{{ t("address.website") }}</dt>
                    <dd>
                      <a :href="website(contact.address.website)" target="_blank" rel="noopener noreferrer">{{
                        website(contact.address.website)
                      }}</a>
                    </dd>
                  </template>
                </dl>
                <div class="d-flex mb-2">
                  <span v-if="contact.address.is_company == 1" class="badge rounded-pill bg-secondary me-1">{{
                    t("general.company")
                  }}</span>
                  <span v-if="contact.address.is_person == 1" class="badge rounded-pill bg-secondary me-1">{{
                    t("general.person")
                  }}</span>
                </div>
                <div class="d-flex">
                  <span
                    v-for="category in contact.address.categories"
                    :key="category.id"
                    :data-id="category.id"
                    class="badge rounded-pill me-1 mb-1 bg-primary"
                    >{{ category.category }}</span
                  >
                </div>
              </div>
            </div>
            <!-- <div class="card-footer bg-transparent border-success">Footer</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import storeConfig from "../../store/config";
import { phone, mobile, fax, website } from "@/composables/address.js";
import AddressNavigation from "@/components/navigation/Address.vue";
import AddressesContactSearch from "@/components/addresses/AddressesContactSearch.vue";
import { useToast } from "vue-toastification";
export default {
  components: {
    AddressNavigation,
    AddressesContactSearch,
  },
  name: "Contactof",
  setup() {
    const route = useRoute();
    const id = route.params.id;
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const currentUser = computed(() => store.getters.user);
    const toast = useToast();

    const addresscontacts = ref([]);
    const address = ref({});

    const searchActive = ref(false);

    async function getAddresscontacts() {
      if (route.params.id) {
        try {
          await store.dispatch("addLoading", 1);
          let result = await axios.get(storeConfig.apiBaseUrl + "search/addresscontact/bycontact", {
            params: {
              contact_id: route.params.id,
            },
          });
          addresscontacts.value = result.data;
        } catch (err) {
          if (err.response.status == 401) {
            store.dispatch("logout");
            router.push({ name: "Login" });
          }
        } finally {
          await store.dispatch("addLoading", -1);
        }
      }
    }

    async function getAddress() {
      if (route.params.id) {
        try {
          await store.dispatch("addLoading", 1);
          let result = await axios.get(storeConfig.apiBaseUrl + "addresses/" + route.params.id);
          address.value = result.data;
        } catch (err) {
          if (err.response && err.response.status == 401) {
            store.dispatch("logout");
            router.push({ name: "Login" });
          }
        } finally {
          await store.dispatch("addLoading", -1);
        }
      }
    }

    async function getAll() {
      // await getAddresscontacts();
      await Promise.all([getAddresscontacts(), getAddress()]);
    }

    function toggleSearch() {
      searchActive.value = !searchActive.value;
    }

    async function addContact(contactAddress, address2categoryId, id) {
      // console.log(contactAddress.id, address2categoryId, id);
      store.dispatch("addLoading", 1);
      try {
        const data = {
          address_id: contactAddress.id,
          contact_id: id,
          address2category_id: address2categoryId,
          created_by: currentUser.value.id,
        };
        await axios.post(storeConfig.apiBaseUrl + "addresscontact", data);
        toast.success('"' + t("nav.contactof") + '" ' + t("general.successfully") + " " + t("general.added") + ".");
        await getAddresscontacts();
        // getAll();
        searchActive.value = false;
      } catch (err) {
        if (err.response && err.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        store.dispatch("addLoading", -1);
      }
    }

    async function removeContactOf(id) {
      // console.log(id);
      store.dispatch("addLoading", 1);
      try {
        await axios.delete(storeConfig.apiBaseUrl + "addresscontact/" + id);
        toast.success(t("address.address") + " " + t("general.successfully") + " " + t("general.deleted") + ".");
        await getAddresscontacts();
        // await getAll();
      } catch (err) {
        if (err.response && err.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        store.dispatch("addLoading", -1);
      }
    }

    getAll();

    return {
      addresscontacts,
      address,
      displayName: computed(() => {
        if (address.value.name) {
          return address.value.name;
        } else if (address.value.forename && address.value.surname) {
          return address.value.forename + " " + address.value.surname;
        } else if (address.value.forename) {
          return address.value.forename;
        } else if (address.value.surname) {
          return address.value.surname;
        } else {
          return "";
        }
      }),
      phone,
      mobile,
      fax,
      website,
      id,
      t,
      toggleSearch,
      searchActive,
      addContact,
      removeContactOf,
    };
  },
};
</script>
